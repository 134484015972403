import React from "react";
import { graphql } from "gatsby";
import clsx from "clsx";
import Container from "@material-ui/core/Container";
import _classes from "../styles/main.module.css";
import Cards from "../components/cards";
import Contact from "../components/contact";
import Demo from "../components/demo";
import Layout from "../components/layout";
import ModelSteps from "../components/model-steps";
import SEO from "../components/seo";
import { scheduleDiscoveryCall } from "../utils/calendly";

const Product = ({ data }) => {
  const { markdownRemark } = data;
  const { frontmatter: fm } = markdownRemark;

  return (
    <Layout>
      <SEO title={fm.seoTitle} description={fm.seoDescription} />

      <section style={{ margin: "150px 0 60px" }}>
        <Container>
          <div style={{ maxWidth: 800, margin: "auto", textAlign: "center" }}>
            <h1
              style={{ fontSize: 40, marginBottom: 30, fontWeight: "normal" }}
            >
              {fm.page_title}
            </h1>

            <a className={_classes.button} href="#contact">
              Contact Sales
            </a>
          </div>
        </Container>
      </section>

      <section>
        <Container>
          <Demo demo={fm.demo} />
        </Container>
      </section>

      <section style={{ marginTop: 120, overflow: "hidden" }}>
        <Container>
          <h2 style={{ fontSize: 36, fontWeight: "normal", margin: 30 }}>
            Use Cases
          </h2>
          <Cards cards={fm.use_cases.cards} />
        </Container>
      </section>

      <section
        style={{
          margin: "100px 0",
          padding: "80px 0",
          background: "#546fff",
          color: "#fff",
          textAlign: "center",
        }}
      >
        <Container>
          <div style={{ maxWidth: 800, margin: "auto" }}>
            <p style={{ fontSize: 24, margin: "auto" }}>{fm.cta_heading1}</p>
            <p style={{ fontSize: 20, margin: "20px auto 0" }}>
              {fm.cta_heading2}
            </p>
            <button
              onClick={scheduleDiscoveryCall}
              className={clsx(_classes.button, _classes.button_outlined)}
              style={{ background: "#000", color: "#fff", marginTop: 40 }}
            >
              Schedule a Demo
            </button>
          </div>
        </Container>
      </section>

      <section style={{ margin: "100px 0" }}>
        <Container>
          <div style={{ maxWidth: 800, margin: "auto" }}>
            <ModelSteps how_it_works={fm.model} />
          </div>
        </Container>
      </section>

      <Contact />
    </Layout>
  );
};

export default Product;

export const pageQuery = graphql`
  query($path: String!) {
    markdownRemark(frontmatter: { path: { eq: $path } }) {
      html
      frontmatter {
        path
        seoTitle
        seoDescription
        page_title
        demo {
          input
          output {
            image {
              url
            }
            result
          }
          demo_type
          output_type
        }
        use_cases {
          cards {
            title
            summary
            poster
            href
          }
        }
        cta_heading1
        cta_heading2
        model {
          heading
          heading2
          steps {
            name
            summary
            poster
          }
        }
      }
    }
  }
`;
